import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useHeadquartersList() {
  // Use toast
  const toast = useToast()

  const refHeadquartersListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'nome', sortable: true },
    { key: 'telefono', sortable: true },
    { key: 'città', sortable: true },
    { key: 'azioni' },
  ]
  const fields = {
    id: 'id',
    nome: 'name',
    telefono: 'phone_number',
    città: 'address_city',
  }
  const perPage = ref(10)
  const totalHeadquarters = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refHeadquartersListTable.value ? refHeadquartersListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalHeadquarters.value,
    }
  })

  const refetchData = () => {
    refHeadquartersListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchHeadquarters = (ctx, callback) => {
    store
      .dispatch('app-headquarters/fetchHeadquarters', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        include_deactivated: true,
      })
      .then(response => {
        const headquarters = response.data[0]
        const total = response.data[1]

        callback(headquarters)
        totalHeadquarters.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
        toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel caricamento delle Sedi',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchHeadquarters,
    tableColumns,
    perPage,
    currentPage,
    totalHeadquarters,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refHeadquartersListTable,

    refetchData,
  }
}
